
import React, { useState } from "react"
import Navbar from "../components/navbar";
import SinglePageHeader from "../components/singlePageHeader";
import Layout from "../components/layout";

import project1 from "../images/portfolio/project1.jpg"
import project2 from "../images/portfolio/project2.jpg"
import project3 from "../images/portfolio/project3.jpg"
import project4 from "../images/portfolio/project4.jpg"
import project5 from "../images/portfolio/project5.jpg"
import project6 from "../images/portfolio/project6.jpg"
import project7 from "../images/portfolio/project7.jpg"
import project8 from "../images/portfolio/project8.jpg"
import project9 from "../images/portfolio/project9.jpg"
import project10 from "../images/portfolio/project10.jpg"
import project11 from "../images/portfolio/project11.jpg"
import project12 from "../images/portfolio/project12.jpg"
import project13 from "../images/portfolio/project13.jpg"
import project14 from "../images/portfolio/project14.jpg"
import project15 from "../images/portfolio/project15.jpg"
import project16 from "../images/portfolio/project16.jpg"
import project17 from "../images/portfolio/project17.jpg"
import project18 from "../images/portfolio/project18.jpg"
import project19 from "../images/portfolio/project19.jpg"
import project20 from "../images/portfolio/project20.jpg"
import CircleFollow from "./index";





const ProjectDetails = ({location}) => {
   return (
        <div className="project-content-page">
            <Layout change="singleHeader">
            <Navbar location="projectDetails"/>
            <SinglePageHeader expertiseType={"ourworks"}/>
                <section className="skew-bg project-detail-scetion" id="yahooSpreets">
                    <div className="container-lg container-md">
                      <div className="row">
                         <div className="col-lg-6">
                            <div className="case-single-img">
                               <img className="img-fluid" src={project1} alt="tick" />
                            </div>
                         </div>
                  <div className="col-lg-6">
                     <div className="case-details-inner">
                        <div className="heading">
                           <h3 className=" content-title border-none">Yahoo Spreets</h3>
                        </div>
                        <ul className="ts-list">
                           <li>Spreets is an ecommerce website featuring a daily deal on the best things to do, see, eat and buy in cities across Australia.</li>
                           <li>Receive day's best deal from a range of group buying sites & view all the deals near you, or explore another city.
                           </li>
                           <li>Spreets was acquired by Yahoo for $40 million after 11 months of development.</li>
                        </ul>
                     </div>
                  </div>
               </div>
                    </div>
               </section>

               <section className="skew-bg project-detail-scetion" id="launchpad">
                  <div className="container-lg container-md">
                     <div className="row">
                        <div className="col-lg-6">
                           <div className="case-single-img">
                              <img className="img-fluid" src={project2} alt="tick" />
                           </div>
                        </div>
                        <div className="col-lg-6">
                           <div className="case-details-inner">
                              <div className="heading">
                                 <h3 className=" content-title border-none">Launchpad</h3>
                              </div>
                              <ul className="ts-list">
                                 <li>Launchpad6 is an online contest platform helping businesses engage and convert new customers.</li>
                                 <li>It is used by organizations to run video contests & talent searches as well as crowd source video content for advertisements, product support, education etc.</li>
                                 <li>It allows organizations to rapidly deploy full featured video sites across the Web, Facebook, Mobile & Connected TV’s.</li>
                              </ul>
                           </div>
                        </div>
                     </div>
                  </div>
               </section>
               <section className="skew-bg project-detail-scetion" id="civilAviationAuthority">
                  <div className="container-lg container-md">
                     <div className="row">
                        <div className="col-lg-6">
                           <div className="case-single-img">
                              <img className="img-fluid" src={project3} alt="tick" />
                           </div>
                        </div>
                        <div className="col-lg-6">
                           <div className="case-details-inner">
                              <div className="heading">
                                 <h3 className=" content-title border-none">UAE Civil Aviation Authority</h3>
                              </div>
                              <ul className="ts-list">
                                 <li>Xminds built a comprehensive platform for the air accident investigators that consolidates all the investigation data into one database system.</li>
                                 <li>It provides a platform for regional civil aviation organization to view real time data pertaining to safety recommendations from the Air Accident Investigation team.</li>
                              </ul>
                           </div>
                        </div>
                     </div>
                  </div>
               </section>

               <section className="skew-bg project-detail-scetion" id="binu">
                  <div className="container-lg container-md">
                     <div className="row">
                        <div className="col-lg-6">
                           <div className="case-single-img">
                              <img className="img-fluid" src={project4} alt="tick" />
                           </div>
                        </div>
                        <div className="col-lg-6">
                           <div className="case-details-inner">
                              <div className="heading">
                                 <h3 className=" content-title border-none">Binu</h3>
                              </div>
                              <ul className="ts-list">
                                 <li>BiNu is an Australian based enterprise platform that engages its mobile audience by making content delivery data free.
                                 </li>
                                 <li>Xminds has built a publisher portal, using which the user can register an account and start building website or mobile app development.</li>
                                 <li>Apart from this Xminds has created a user management dashboard for managing ads for their publishers and media buyers or advertisers.</li>
                              </ul>
                           </div>
                        </div>
                     </div>
                  </div>
               </section>

               <section className="skew-bg project-detail-scetion" id="pillo">
                  <div className="container-lg container-md">
                     <div className="row">
                        <div className="col-lg-6">
                           <div className="case-single-img">
                              <img className="img-fluid" src={project5} alt="tick" />
                           </div>
                        </div>
                        <div className="col-lg-6">
                           <div className="case-details-inner">
                              <div className="heading">
                                 <h3 className=" content-title border-none">Pillo</h3>
                              </div>
                              <ul className="ts-list">
                                 <li>An online pharmacy linked to managing medication services and order placement.</li>
                                 <li>They dispense NHS prescriptions (paper and electronic) and give advice on how to get the most benefit from your medicines.</li>
                              </ul>
                           </div>
                        </div>
                     </div>
                  </div>
               </section>

               <section className="skew-bg project-detail-scetion" id="pocketSports">
                  <div className="container-lg container-md">
                     <div className="row">
                        <div className="col-lg-6">
                           <div className="case-single-img">
                              <img className="img-fluid" src={project6} alt="tick" />
                           </div>
                        </div>
                        <div className="col-lg-6">
                           <div className="case-details-inner">
                              <div className="heading">
                                 <h3 className=" content-title border-none">Pocket Sports</h3>
                              </div>
                              <ul className="ts-list">
                                 <li>An e-commerce platform from the UK selling premium quality active wear to women around the world.</li>
                                 <li>Features include Filtered navigation, Order management, Inventory management, Social media integration, Online payment integration.</li>

                              </ul>
                           </div>
                        </div>
                     </div>
                  </div>
               </section>

               <section className="skew-bg project-detail-scetion" id="logisticPlatform">
                  <div className="container-lg container-md">
                     <div className="row">
                        <div className="col-lg-6">
                           <div className="case-single-img">
                              <img className="img-fluid" src={project7} alt="tick" />
                           </div>
                        </div>
                        <div className="col-lg-6">
                           <div className="case-details-inner">
                              <div className="heading">
                                 <h3 className=" content-title border-none">
                                    A Video logistic platform</h3>
                              </div>
                              <ul className="ts-list">
                                 <li>Collaborate with the technology information security, and business partners.</li>
                                 <li>Find and address performance issues are main.</li>
                                 <li>Assisting senior consultants projects priorate first.</li>
                                 <li>Share best both practices and knowledge need to think.</li>
                              </ul>
                           </div>
                        </div>
                     </div>
                  </div>
               </section>

               <section className="skew-bg project-detail-scetion" id="yourMoo">
                  <div className="container-lg container-md">
                     <div className="row">
                        <div className="col-lg-6">
                           <div className="case-single-img">
                              <img className="img-fluid" src={project8} alt="tick" />
                           </div>
                        </div>
                        <div className="col-lg-6">
                           <div className="case-details-inner">
                              <div className="heading">
                                 <h3 className=" content-title border-none">YourMoo</h3>
                              </div>
                              <ul className="ts-list">
                                 <li>YourMOO is your local dairy’s opportunity to allow its customers’ access to their Milk Order Online.</li>
                                 <li>It helps you improve your business by taking advantage of the 85% of households that order and pay for Goods on-line.</li>
                              </ul>
                           </div>
                        </div>
                     </div>
                  </div>
               </section>

               <section className="skew-bg project-detail-scetion" id="passpod">
                  <div className="container-lg container-md">
                     <div className="row">
                        <div className="col-lg-6">
                           <div className="case-single-img">
                              <img className="img-fluid" src={project9} alt="tick" />
                           </div>
                        </div>
                        <div className="col-lg-6">
                           <div className="case-details-inner">
                              <div className="heading">
                                 <h3 className=" content-title border-none">PassPod</h3>
                              </div>
                              <ul className="ts-list">
                                 <li>A travel platform in Indonesia that connects travelers with the local tour, activities, and other travelers need.</li>
                                 <li>Features include Find activities,tour & attractions, Integrated online booking, Online payment integration, Paperless etickets, Exclusive Promo and Travel insurance.</li>
                              </ul>
                           </div>
                        </div>
                     </div>
                  </div>
               </section>

               <section className="skew-bg project-detail-scetion" id="betterClinics">
                  <div className="container-lg container-md">
                     <div className="row">
                        <div className="col-lg-6">
                           <div className="case-single-img">
                              <img className="img-fluid" src={project10} alt="tick" />
                           </div>
                        </div>
                        <div className="col-lg-6">
                           <div className="case-details-inner">
                              <div className="heading">
                                 <h3 className=" content-title border-none">Better Clinics</h3>
                              </div>
                              <ul className="ts-list">
                                 <li>Better Clinics is an online practice management software for health & fitness professionals.</li>
                                 <li>Its features are appointment scheduling, online booking, customer management, invoices, marketing, team management.</li>
                                 <li>Assisting senior consultants projects priorate first.</li>
                                 <li>One can easily access their clinical information securely from anywhere.</li>
                              </ul>
                           </div>
                        </div>
                     </div>
                  </div>
               </section>
               <section className="skew-bg project-detail-scetion" id="voip">
                  <div className="container-lg container-md">
                     <div className="row">
                        <div className="col-lg-6">
                           <div className="case-single-img">
                              <img className="img-fluid" src={project11} alt="tick" />
                           </div>
                        </div>
                        <div className="col-lg-6">
                           <div className="case-details-inner">
                              <div className="heading">
                                 <h3 className=" content-title border-none">Dr. Voip</h3>
                              </div>
                              <ul className="ts-list">
                                 <li>DrVoip is a portal that is basically an SMS to Email to SMS application using keyword matching.</li>
                                 <li>It handles a variety of communication channels with SMS and call centre capabilities.</li>
                                 <li>Users can setup various channels for their support services with SMS push pull services or even as a full fledged call centre module that can handle - voice, video, chat.</li>
                              </ul>
                           </div>
                        </div>
                     </div>
                  </div>
               </section>

               <section className="skew-bg project-detail-scetion" id="kingContent">
                  <div className="container-lg container-md">
                     <div className="row">
                        <div className="col-lg-6">
                           <div className="case-single-img">
                              <img className="img-fluid" src={project12} alt="tick" />
                           </div>
                        </div>
                        <div className="col-lg-6">
                           <div className="case-details-inner">
                              <div className="heading">
                                 <h3 className=" content-title border-none">King Content</h3>
                              </div>
                              <ul className="ts-list">
                                 <li>Australia's multi award winning digital content marketing and creation agency now acquired by Isentia.</li>
                                 <li>Xminds has built a digital experience platform from scratch to connect and create a personalized CMS for King Contents customers with a secure platform named Communiqué.
                                 </li>
                                 <li>Communiqué is a software as a service technology platform that provides King Content and its clients with a centralized system that houses the entire process of its content marketing.</li>
                              </ul>
                           </div>
                        </div>
                     </div>
                  </div>
               </section>
               <section className="skew-bg project-detail-scetion" id="pleezpay">
                  <div className="container-lg container-md">
                     <div className="row">
                        <div className="col-lg-6">
                           <div className="case-single-img">
                              <img className="img-fluid" src={project13} alt="tick" />
                           </div>
                        </div>
                        <div className="col-lg-6">
                           <div className="case-details-inner">
                              <div className="heading">
                                 <h3 className=" content-title border-none">Pleezpay</h3>
                              </div>
                              <ul className="ts-list">
                                 <li>A service that takes the pain out of getting payment from large groups, which is easy to use, maybe even fun, while at the same time offering powerful reconciliation of your payments.</li>
                                 <li>Whatever your event type, from mass participation to a small function, PleezPay have the registration forms, and control that you need to ensure your events success.
                                 </li>
                                 <li>With custom branding, your brand is front and centre throughout.</li>
                              </ul>
                           </div>
                        </div>
                     </div>
                  </div>
               </section>
               <section className="skew-bg project-detail-scetion" id="carlton">
                  <div className="container-lg container-md">
                     <div className="row">
                        <div className="col-lg-6">
                           <div className="case-single-img">
                              <img className="img-fluid" src={project14} alt="tick" />
                           </div>
                        </div>
                        <div className="col-lg-6">
                           <div className="case-details-inner">
                              <div className="heading">
                                 <h3 className=" content-title border-none">Carlton & United Breweries</h3>
                              </div>
                              <ul className="ts-list">
                                 <li>CUB brews some of Australia’s most iconic and loved beers.</li>
                                 <li>It is committed to the responsible consumption of alcohol, including working to reduce harmful drinking practices in Australia.
                                 </li>
                              </ul>
                           </div>
                        </div>
                     </div>
                  </div>
               </section>
               <section className="skew-bg project-detail-scetion" id="puntclub">
                  <div className="container-lg container-md">
                     <div className="row">
                        <div className="col-lg-6">
                           <div className="case-single-img">
                              <img className="img-fluid" src={project15} alt="tick" />
                           </div>
                        </div>
                        <div className="col-lg-6">
                           <div className="case-details-inner">
                              <div className="heading">
                                 <h3 className=" content-title border-none">Punt Club</h3>
                              </div>
                              <ul className="ts-list">
                                 <li>Punt Club is an application that allows friends to bet together.</li>
                                 <li>It lets club members to bet in one easy online location and keep track of all the details.</li>
                                 <li>Punt Club makes the mandatory group purchases easier too.</li>
                              </ul>
                           </div>
                        </div>
                     </div>
                  </div>
               </section>
               <section className="skew-bg project-detail-scetion" id="techsydney">
                  <div className="container-lg container-md">
                     <div className="row">
                        <div className="col-lg-6">
                           <div className="case-single-img">
                              <img className="img-fluid" src={project16} alt="tick" />
                           </div>
                        </div>
                        <div className="col-lg-6">
                           <div className="case-details-inner">
                              <div className="heading">
                                 <h3 className=" content-title border-none">Tech Sydney</h3>
                              </div>
                              <ul className="ts-list">
                                 <li>TechSydney is an entrepreneur-led industry group that connects, supports and promotes the tech industry in Sydney.
                                 </li>
                                 <li>It is an independent industry group led by a team of experienced proven entrepreneurs who are determined to make this happen.
                                 </li>
                                 <li>They run events, founders’ forums, workshops, a recruitment platform and host other initiatives for their members.
                                 </li>
                              </ul>
                           </div>
                        </div>
                     </div>
                  </div>
               </section>
               <section className="skew-bg project-detail-scetion" id="tickle">
                  <div className="container-lg container-md">
                     <div className="row">
                        <div className="col-lg-6">
                           <div className="case-single-img">
                              <img className="img-fluid" src={project17} alt="tick" />
                           </div>
                        </div>
                        <div className="col-lg-6">
                           <div className="case-details-inner">
                              <div className="heading">
                                 <h3 className=" content-title border-none">Tickled Media</h3>
                              </div>
                              <ul className="ts-list">
                                 <li>Tickled Media is a South East Asian focused parenting digital publisher.</li>
                                 <li>They publish the Asianparent.com, Kidlander.com and Pregnant.sg.</li>
                              </ul>
                           </div>
                        </div>
                     </div>
                  </div>
               </section>
               <section className="skew-bg project-detail-scetion" id="podcast">
                  <div className="container-lg container-md">
                     <div className="row">
                        <div className="col-lg-6">
                           <div className="case-single-img">
                              <img className="img-fluid" src={project18} alt="tick" />
                           </div>
                        </div>
                        <div className="col-lg-6">
                           <div className="case-details-inner">
                              <div className="heading">
                                 <h3 className=" content-title border-none">The Podcast Network</h3>
                              </div>
                              <ul className="ts-list">
                                 <li>The Podcast Network brings together talented and committed podcasters from around the world to produce high quality programming for a global audience.</li>
                                 <li>They launched the world’s first podcast network in February 2005.</li>
                                 <li>It is a leading publisher of quality podcasts on subjects including technology, business, entertainment, lifestyle, comedy and history.</li>
                              </ul>
                           </div>
                        </div>
                     </div>
                  </div>
               </section>
               <section className="skew-bg project-detail-scetion" id="spoke">
                  <div className="container-lg container-md">
                     <div className="row">
                        <div className="col-lg-6">
                           <div className="case-single-img">
                              <img className="img-fluid" src={project19} alt="tick" />
                           </div>
                        </div>
                        <div className="col-lg-6">
                           <div className="case-details-inner">
                              <div className="heading">
                                 <h3 className=" content-title border-none">Spoke Commerce</h3>
                              </div>
                              <ul className="ts-list">
                                 <li>Spoke Commerce is an affordable e-Commerce solution for the custom clothing industry.</li>
                                 <li>It is an online multi-store platform to service the rapidly developing needs of the global tailoring market.</li>
                              </ul>
                           </div>
                        </div>
                     </div>
                  </div>
               </section>
               <section className="skew-bg project-detail-scetion" id="logisticPlatform">
                  <div className="container-lg container-md">
                     <div className="row">
                        <div className="col-lg-6">
                           <div className="case-single-img">
                              <img className="img-fluid" src={project20} alt="sass-pltform" />
                           </div>
                        </div>
                        <div className="col-lg-6">
                           <div className="case-details-inner">
                              <div className="heading">
                                 <h3 className=" content-title border-none">A major video SaaS platform</h3>
                              </div>
                              <ul className="ts-list">
                                 <li>The company is the first Adaptive Video Logistics tool for content distributors to free up their data pipeline.</li>
                                 <li>Its cloud based SaaS platform empowers businesses by providing them with a mechanism to act on their data and not just analyse it.</li>
                              </ul>
                           </div>
                        </div>
                     </div>
                  </div>
               </section>
                </Layout>
         </div>
    )
}


export default ProjectDetails